import request from '@/utils/request'


// 查询平台认证列表
export function listAuth(query) {
  return request({
    url: '/waiter/auth/list',
    method: 'get',
    params: query
  })
}

// 查询平台认证分页
export function pageAuth(query) {
  return request({
    url: '/waiter/auth/page',
    method: 'get',
    params: query
  })
}

// 查询平台认证详细
export function getAuth(data) {
  return request({
    url: '/waiter/auth/detail',
    method: 'get',
    params: data
  })
}

// 新增平台认证
export function addAuth(data) {
  return request({
    url: '/waiter/auth/add',
    method: 'post',
    data: data
  })
}

// 修改平台认证
export function updateAuth(data) {
  return request({
    url: '/waiter/auth/edit',
    method: 'post',
    data: data
  })
}

// 删除平台认证
export function delAuth(data) {
  return request({
    url: '/waiter/auth/delete',
    method: 'post',
    data: data
  })
}
