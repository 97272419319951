<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="服务者" prop="waiterId" >
<!--        <a-input v-model="form.waiterId" placeholder="请输入服务者" />-->
        <a-select
          style="width: 100%"
          @change="handleChange"

          v-model="form.waiterId"
          option-filter-prop="children"
          :filter-option="filterOption"
        >
          <a-select-option v-for="(item, index) in list" :key="index" :value="item.id">
            <span>{{ item.userName }}</span>
          </a-select-option>
        </a-select>

      </a-form-model-item>
      <a-form-model-item label="名称" prop="name" >
        <a-input v-model="form.name" placeholder="请输入名称" />
      </a-form-model-item>
        <a-form-model-item :label="$t('经纪人/服务者.身份设置.图片')" prop="picture" >
          <file-upload type="image" :defaultList="form.imgArr" :count="1"
                       @input="getImg($event, 'picture')"></file-upload>
        </a-form-model-item>
      <a-form-model-item label="详细介绍" prop="introduce" >
        <a-input v-model="form.introduce" placeholder="请输入内容" type="textarea" allow-clear />
<!--        <editor v-model="form.introduce" />-->
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getAuth, addAuth, updateAuth } from '@/api/waiter/auth'
import Editor from '@/components/Editor'
import { listWaiter } from '@/api/user/waiter'
import {guid} from "@/utils/ruoyi";


export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor,
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      list: [],
      // 表单参数
      form: {
        id: null,
        imgArr: [],

        waiterId: null,

        name: null,

        picture: null,

        introduce: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        waiterId: [
          { required: true, message: '服务者id,外键{zb_user_waiter.id}不能为空', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '名称不能为空', trigger: 'blur' }
        ],
        picture: [
          { required: true, message: '图片不能为空', trigger: 'blur' }
        ],
        introduce: [
          { required: true, message: '详细介绍不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
    this.getlistWaiter()
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        waiterId: null,
        name: null,
        picture: null,
        introduce: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getAuth({"id":id}).then(response => {
        this.form = response.data

        let that = this;
        this.form.imgArr = []
        this.form.imgArr.push({
          uid: guid(8, 10),
          name: that.form.picture,
          status: 'done',
          halfUrl: that.form.picture,
          url: that.form.picture,
          path: that.form.picture
        })

        this.open = true
        this.formTitle = '修改'
      })
    },

    //获取服务这列表
    getlistWaiter() {
      listWaiter().then((res) => {
        // console.log('服务者', res)
        this.list = res.data
      })
    },

    handleChange(value) {
      // let arr = []
      // value.forEach((item) => {
      //   this.list.map((item1, index) => {
      //     if (item == index) {
      //       arr.push(item1)
      //     }
      //   })
      // })
      this.id = value
    },

    filterOption(input, option) {
      console.log(input , option);
      return option.componentOptions.propsData.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },

    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateAuth(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addAuth(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    },
    /******************** 上传图片数据处理 start ********************/
    /**前端 需要将图片格式处理成数组，方便数据回显 */
    setImgData(field, img) {
      let fieldImgArr = []
      if (img.indexOf(",") === -1) {
        // 单图
        if (this.form[`${field}Count`] === 1) {
          if (img) {
            fieldImgArr.push({
              uid: guid(8, 10),
              name: img,
              status: 'done',
              halfUrl: img,
              url: img,
              path: img
            })
          }
        }
      } else {
        // 多图
        if (this.form[`${field}Count`] > 1) {
          let imgArr = img.split(",")
          if (imgArr.length) {
            imgArr.map(item => {
              fieldImgArr.push({
                uid: guid(8, 10),
                name: img,
                status: 'done',
                halfUrl: img,
                url: item,
                path: item
              })
            })
          }
        }
      }
      return fieldImgArr
    },
    /**上传图片成功回调 */
    getImg(info, fieldName) {
      console.log('info', info)
      if (info.length === 0) {
        this.form[fieldName] = ''
        this.form[`${fieldName}Arr`] = []
      } else {
        if (this.form[`${fieldName}Count`] === 1) {
          this.form[fieldName] = info[0].url
          this.form[`${fieldName}Arr`] = info
        } else {
          this.form[fieldName] = info.map(ele => { return ele.url }).join(",")
          this.form[`${fieldName}Arr`] = info
        }
      }
    },
    /** 点击视频 弹窗打窗口播放视频*/
    async handlePreview(file) {
      this.previewImage = this.form.video || file.video;
      this.previewVisible = true;
      this.$nextTick(() => {
        this.$refs.myVideo.play()
      })
    },
    handleCancel() {
      this.previewVisible = false;
    },
    /******************** 上传图片数据处理 end   ********************/
  }
}
</script>
